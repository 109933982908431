import axios from 'axios';
import { addTokenInRequestHeader } from '@/helpers/authCheck.js';
import { BASE_API_ENDPOINT, BA_STYLE_GUIDES_ENDPOINT, FACT_SHEETS } from '../endpointConstants';

const factSheetAxiosInstance = axios.create({
    baseURL: BASE_API_ENDPOINT + BA_STYLE_GUIDES_ENDPOINT + FACT_SHEETS
});

// request interceptor
factSheetAxiosInstance.interceptors.request.use(
    request => {
        // add token in header
        request = addTokenInRequestHeader(request);
        return request;
    }
);

export function fetchFactSheetsService () {
    return factSheetAxiosInstance({
        method: 'GET'
    });
};

export function saveFetchFactSheetsService (data) {
    return factSheetAxiosInstance({
        method: 'PUT',
        data
    });
};

export function publishFetchFactSheets (data) {
    return factSheetAxiosInstance({
        method: 'POST',
        data
    });
};
