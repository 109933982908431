// @NOTE: mock api endpoints start
export const BASE_MOCK_API_ENDPOINT = process.env.VUE_APP_BASE_MOCK_API_URL;

/** auth endpoints */
export const AUTH_ENDPOINT = '/auth';
export const LOGIN_ENDPOINT = '/login';

/** brand assurance endpoints */
export const BRAND_ASSURANCE_ENDPOINT = '/brand-assurance';
// BA submission endpoints
export const MOCK_SUBMISSION_ENDPOINT = '/submissions';
export const MOCK_SUBMISSION_DETAILS_ENDPOINT = '/details';
export const CLAIM_TASKS_ENDPOINT = '/claim-tasks';
export const UNCLAIM_TASKS_ENDPOINT = '/unclaim-tasks';
export const ASSIGN_TASKS_ENDPOINT = '/assign-tasks';
export const REASSIGN_TASKS_ENDPOINT = '/reassign-tasks';
export const EXPORT_TO_EXCEL_ENDPOINT = '/export-to-excel';
export const DOWNLOAD_RECEIPT_ENDPOINT = '/download-receipt';
export const RECEIPT_ENDPOINT = '/receipt';
export const ANNUAL_SAMPLES_ENDPOINT = '/annual-samples';
export const SUBMISSION_STEPS_ENDPOINT = '/steps';
export const SUBMISSION_TYPES_ENDPOINT = '/types';
export const SEARCH_SUBMISSIONS_ENDPOINT = '/search';
export const SUBMISSION_SAVED_SEARCHES_ENDPOINT = '/saved-search';
export const SUBMISSION_STAGES_ENDPOINT = '/stages';
export const SUBMISSION_STATUS_ENDPOINT = '/status';
export const SUBMISSION_CATEGORIES_ENDPOINT = '/categories';
export const SUBMISSION_LICENSEE_CONTACT_ENDPOINT = '/licensee-contact';
export const SUBMISSION_CONTRACTS_ENDPOINT = '/contracts';
export const CANCEL_SUBMISSION_ENDPOINT = '/cancel';
export const SUBMITTION_COPY_ENDPOINT = '/copy';

// BA style guides endpoints
export const BA_STYLE_GUIDES_ENDPOINT = '/style-guides';
// BA licensee endpoints
export const LICENSEE_ENDPOINT = '/licensee';
export const LICENSEE_NAMES_ENDPOINT = '/names';
export const LICENSEE_CONTRACT_NUMBER_ENDPOINT = '/contract-number';
export const LICENSEE_DETAILS_ENDPOINT = '/details';
export const LICENSEE_CONTACTS_ENDPOINT = '/contacts';
// BA product line report endpoints
export const PRODUCT_LINE_REPORT_ENDPOINT = '/product-line-report';
export const PRODUCT_LINE_REPORT_COUNT_ENDPOINT = '/count';
export const PRODUCT_LINE_REPORT_DOWNLOAD_ENDPOINT = '/download';
// BA collaborations endpoints
export const MOCK_COLLABORATIONS_ENDPOINT = '/collaborations';
export const MOCK_COLLABORATORS_ENDPOINT = '/collaborators';

/** users endpoints */
export const USERS_ENDPOINT = '/users';
// @NOTE: mock api endpoints end

// @NOTE: real api endpoints start
export const BASE_API_ENDPOINT = process.env.VUE_APP_BASE_API_URL;
export const BASE_REPORT_API_ENDPOINT = process.env.VUE_APP_REPORT_API_URL;

/** style guides endpoints */
export const STYLE_GUIDES_ENDPOINT = '/style-guides';
export const SEARCH_ASSETS_ENDPOINT = '/search-assets';
export const ASSETS_FILTERS_ENDPOINT = '/search-filters';
export const SAVE_SEARCHES_ENDPOINT = '/saved-searches';
export const BASKET_ENDPOINT = '/basket';

/** user endpoints */
export const USER_ENDPOINT = '/user';
export const USER_DETAILS_ENDPOINT = '/details';
export const USER_ROLES_ENDPOINT = '/get-roles';
export const USER_UPDATE_ENDPOINT = '/update-user-role';

// save preference
export const USER_PREFERENCES = 'preferences';

/** master data endpoints */
export const MASTER_DATA_ENDPOINT = '/master-data';
export const MASTER_DATA_STAGES_ENDPOINT = '/stages';
export const MASTER_DATA_POSSIBLE_STAGES_ENDPOINT = '/possible-stages';
export const MASTER_DATA_STATUS_ENDPOINT = '/statuses';
export const MASTER_DATA_STEPS_ENDPOINT = '/steps';
export const MASTER_DATA_SUBMISSION_ENDPOINT = '/submission';
export const MASTER_DATA_GAME_CODE_ENDPOINT = '/game-codes';
export const MASTER_REQUESTED_STAGE_ENDPOINT = '/licensee-requested-stage-config';

/** submissions endpoints */
export const SUBMISSION_ENDPOINT = '/submission';
export const GENERATE_SUBMISSION_ID_ENDPOINT = '/generate-identifier';
export const VALIDATE_SKU_ENDPOINT = '/validate-sku';
export const VALIDATE_MULTIPLE_SKU_ENDPOINT = '/validate-multiple-skus';
export const SUBMISSION_WORKITEMS_ENDPOINT = '/workitems';
export const SUBMISSION_SEARCH_ENDPOINT = '/search';
export const SUBMISSION_DETAILS_ENDPOINT = '/details';
export const SUBMISSION_COMMENT_ENDPOINT = '/comment';
export const SUBMISSION_TASKS_ENDPOINT = '/task';
export const SUBMISSION_ADVANCE_SEARCH_ENDPOINT = '/advanced-search';
export const SUBMISSION_PKGING_ENDPOINT = '/packaging-submissions';

/** drm services endpoints */
export const DRM_ENDPOINT = '/drm';
export const LICENSEES_ENDPOINT = '/licensees';
export const LICENSEES_DETAILS_ENDPOINT = '/details';
export const LICENSEE_CONTRACT_ENDPOINT = '/licensee-contracts';
export const STYLE_GUIDE_CHARACTERS_ENDPOINT = '/characters';
export const DRM_CONTRACTS_ENDPOINT = '/contract';
export const DRM_REPLACE_CONTRACT_ENDPOINT = '/replacement-contracts';
export const DRM_USER_LICENSEES = '/user-licensees';
export const SUBMISSION_CONTRACTUAL_SAMPLES_DETAILS = '/contractual-samples';

/** collaboration endpoints */
export const COLLABORATIONS_ENDPOINT = '/collaboration';
export const COLLABORATION_DETATILS_ENDPOINT = '/details';
export const COLLABORATION_TEMPLATE_ENDPOINT = '/template';
export const SUBMISSION_SAVED_SEARCH_ENDPOINT = '/saved-search';
export const COLLABORATION_STATUS_ENDPOINT = '/status';
export const DELETE_COLLABORATION_TEMPLATE_ENDPOINT = '/delete';

export const DRM_CONTRACT_SYNC_ENDPOINT = '/contract-sync';
export const DRM_REFRESH_VIEWS_ENDPOINT = '/refresh';
export const MANUAL_SKU_PUSH_ENDPOINT = '/manual-sku-push';
export const EMAIL_ENDPOINT = '/email';

/** transaction handler endpoints */
export const TRANSACTION_HANDLER_ENDPOINT = '/transhandler';
export const DRM_TERRITORIES_ENDPOINT = '/territories';
export const DRM_CHANNELS_ENDPOINT = '/channels';
export const DRM_ARTICLES_ENDPOINT = '/articles';

/** custom line */
export const CUSTOM_LINES_ENDPOINT = '/custom-lines';
export const PRODUCT_LINE_REPORT_PDF_ENDPOINT = '/generate-product-line-report';

// upload endpoints
export const UPLOAD_ATTACHMENTS_SUBMISSIONS_ENDPOINT = '/submission-attachments';

// download attachments
export const DOWNLOAD_ATTACHMENTS_ENDPOINT = '/downloads';
export const DOWNLOAD_JOBS_ATTACHMENTS_ENDPOINT = '/jobs';

// export meta-data
export const EXPORT_META_DATA_STYLE_GUIDES = '/export-data';

/* fact-sheet */
export const FACT_SHEETS = '/fact-sheets';
