import { fetchFactSheetsService, publishFetchFactSheets, saveFetchFactSheetsService, saveFetchFactSheetsServiceToAWS } from '@/api/requests/factSheetRequests.js';

export default {
    namespaced: true,

    state () {
        return {
            newFactSheetsList: null
        };
    },
    getters: {
        getFactSheetsList (state) {
            return state.newFactSheetsList;
        }
    },

    mutations: {
        setFactSheetsList (state, newFactSheetsList) {
            state.newFactSheetsList = newFactSheetsList;
        }
    },
    actions: {
        async fetchFactSheets ({ commit }) {
            const { data: { data } } = await fetchFactSheetsService();
            commit('setFactSheetsList', data);
        },
        async saveFetchFactSheets ({ commit }, { bodyPayload }) {
            const { data: { data } } = await saveFetchFactSheetsService(bodyPayload);
            return data;
        },
        async publishFetchFactSheets ({ commit }, { bodyPayload }) {
            const { data: { data } } = await publishFetchFactSheets(bodyPayload);
            return data;
        }
    }
};
