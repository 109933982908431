import { BASE_MOCK_API_ENDPOINT, USERS_ENDPOINT, USER_ENDPOINT, USER_DETAILS_ENDPOINT, USER_UPDATE_ENDPOINT, USER_PREFERENCES, USER_ROLES_ENDPOINT, BASE_API_ENDPOINT } from '@/api/endpointConstants.js';
import { filterObject } from '@/helpers/util';
import { addTokenInRequestHeader } from '@/helpers/authCheck.js';
import axios from 'axios';

// @TO-DO's: remove mock service instance once all actual services are integrated
// mock service instance starts

// intialize local instance
const mockUsersAxiosInstance = axios.create({
    baseURL: BASE_MOCK_API_ENDPOINT + USERS_ENDPOINT
});

// request interceptor
mockUsersAxiosInstance.interceptors.request.use(request => {
    console.log('>>> REMOVE MOCK : mockUsersAxiosInstance')
    // add token in header
    request = addTokenInRequestHeader(request);
    return request;
});

export function fetchUserNamesService (params) {
    return mockUsersAxiosInstance({
        method: 'GET',
        params: filterObject(params)
    });
}

// @NOTE: actual user service starts from here
const userAxiosInstance = axios.create({
    baseURL: BASE_API_ENDPOINT + USER_ENDPOINT
});

// request interceptor
userAxiosInstance.interceptors.request.use(
  request => {
      // add token in header
      request = addTokenInRequestHeader(request);
      return request;
  }
);

export function fetchAllUsersService (params) {
    return userAxiosInstance({
        method: 'Get',
        params: filterObject(params)
    });
}

export function fetchUserDetailsByUserNameService (params) {
    return userAxiosInstance({
        method: 'Get',
        url: `${USER_DETAILS_ENDPOINT}`,
        params
    });
}

export function fetchAllRolesService () {
    return userAxiosInstance({
        method: 'Get',
        url: `${USER_ROLES_ENDPOINT}`
    });
}

export function updateUserRolesService (data) {
    return userAxiosInstance({
        method: 'Post',
        url: `${USER_UPDATE_ENDPOINT}`,
        data
    });
}

export function saveUserPreferencesService (data) {
    return userAxiosInstance({
        method: 'POST',
        url: `${USER_PREFERENCES}`,
        data
    });
}

export function fetchUserPreferencesService () {
    return userAxiosInstance({
        method: 'GET',
        url: `${USER_PREFERENCES}`
    });
}
