export default {
    namespaced: true,

    state () {
        return {
            submissionColumns: null
        };
    },

    getters: {
        getSubmissionColumns (state) {
            return state.submissionColumns || [];
        },
        getActualListOfCols (state) {
            return [
                {
                    label: 'Submission Id',
                    key: 'submissionId',
                    field: 'submissionId'
                },
                {
                    label: 'Licensee Name',
                    key: 'licenseeName',
                    field: 'licenseeName'
                },
                {
                    label: 'Long Article Description',
                    key: 'longArticleDescription',
                    field: 'longArticleDescription'
                },
                {
                    label: 'Short Article Description',
                    key: 'shortArticleDescription',
                    field: 'shortArticleDescription'
                },
                {
                    label: 'Current Step',
                    key: 'currentStep',
                    field: 'currentStep'
                },
                // {
                //     label: 'Assignee',
                //     key: 'assignUser',
                //     field: 'assignUser'
                // },
                {
                    label: 'Assigned To',
                    key: 'assignUser',
                    field: 'assignedTo'
                },
                {
                    label: 'Submitted Item For Review',
                    key: 'licenseeReqStage',
                    field: 'licenseeReqStage'
                },
                {
                    label: 'Materials Supplied',
                    key: 'materialsSupplied',
                    field: 'materialsSupplied'
                },
                {
                    label: 'Current Stage',
                    key: 'currentStage',
                    field: 'currentStage'
                },
                {
                    label: 'Submission Date',
                    key: 'creationDate',
                    field: 'creationDate'
                },
                {
                    label: 'Style Guide',
                    key: 'styleGuide',
                    field: 'styleGuide'
                },
                {
                    label: 'Last Updated',
                    key: 'lastmodifiedDate',
                    field: 'lastmodifiedDate'
                },
                {
                    label: 'Age at Current Step',
                    key: 'stepAge',
                    field: 'stepAge'
                },
                {
                    label: 'Age at Current Stage',
                    key: 'stageAge',
                    field: 'stageAge'
                },
                {
                    label: 'Submitted By',
                    key: 'createdUser',
                    field: 'createdUser'
                },
                {
                    label: 'Asset Type',
                    key: 'assetType',
                    field: 'assetType'
                },
                {
                    label: 'SKU Number',
                    key: 'skuNumber',
                    field: 'skuNumber'
                },
                {
                    label: 'Press Release Category',
                    key: 'pressReleaseCategory',
                    field: 'pressReleaseCategory'
                },
                {
                    label: 'Game Code',
                    key: 'gameCode',
                    field: 'gameCode'
                },
                {
                    label: 'Line of Business',
                    key: 'genLob',
                    field: 'genLob'
                },
                {
                    label: 'Generic Category',
                    key: 'genCategory',
                    field: 'genCategory'
                },
                {
                    label: 'Type',
                    key: 'genType',
                    field: 'genType'
                },
                {
                    label: 'Submission Type',
                    key: 'submissionTypeName',
                    field: 'submissionTypeName'
                },
                {
                    label: 'Submission Status',
                    key: 'submissionStatus',
                    field: 'submissionStatus'
                },
                {
                    label: 'Date of Current Step',
                    key: 'stepStartDate',
                    field: 'stepStartDate'
                },
                {
                    label: 'Assigned Date',
                    key: 'deliveryDate',
                    field: 'deliveryDate'
                },
                {
                    label: 'Is Safety Needed',
                    key: 'isSafetyNeeded',
                    field: 'isSafetyNeeded'
                },
                {
                    label: 'Safety Documents Included',
                    key: 'safetyDocIncluded',
                    field: 'safetyDocIncluded'
                },
                {
                    label: 'Is Safety Completed',
                    key: 'isSafetyCompleted',
                    field: 'isSafetyCompleted'
                },
                {
                    label: 'Place Safety Hold',
                    key: 'placeSafetyHold',
                    field: 'placeSafetyHold'
                },
                {
                    label: 'Place Sample Hold',
                    key: 'placeSampleHold',
                    field: 'placeSampleHold'
                },
                {
                    label: 'Escalate Supervisor',
                    key: 'escalateToSupervisor',
                    field: 'escalateToSupervisor'
                },
                {
                    label: 'Rush Submission',
                    key: 'rushSubmission',
                    field: 'rushSubmission'
                },
                {
                    label: 'Legacy Submission Id',
                    key: 'legacySubmissionId',
                    field: 'legacySubmissionId'
                },
                {
                    label: 'Date at Current Stage',
                    key: 'stageStartDate',
                    field: 'stageStartDate'
                },
                {
                    label: 'Next Stage',
                    key: 'nextStage',
                    field: 'nextStage'
                },
                {
                    label: 'Next Step',
                    key: 'nextStep',
                    field: 'nextStep'
                },
                {
                    label: 'Creative Status',
                    key: 'creativeStatus',
                    field: 'creativeStatus'
                },
                {
                    label: 'Creative Next Stage',
                    key: 'creativeNextStage',
                    field: 'creativeNextStage'
                },
                {
                    label: 'Legal Status',
                    key: 'legalStatus',
                    field: 'legalStatus'
                },
                {
                    label: 'Legal Next Stage',
                    key: 'legalNextStage',
                    field: 'legalNextStage'
                },
                {
                    label: 'Contract Number',
                    key: 'contractNumber',
                    field: 'contractNumber'
                },
                {
                    label: 'Category',
                    key: 'category',
                    field: 'category'
                }
            ];
        }
    },

    mutations: {
        setSubmissionColumns (state, submissionColumns) {
            state.submissionColumns = submissionColumns;
        }
    },

    actions: {
        updateSubmissionColumns ({ commit }, { submissionColumns }) {
            try {
                commit('setSubmissionColumns', submissionColumns);
            } catch (e) { }
        }
    }
};
