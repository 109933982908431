// alert types
export const WARNING = 'warning';
export const SUCCESS = 'success';
export const ERROR = 'error';

// warning alert messages
export const MAX_FIVE_OPTIONS = 'You can not select more than 5 options.';
export const SEARCH_KEYWORD_REQUIRED = 'Enter search keyword.';
export const SELECT_ATLEAST_ONE_COLUMN = 'Select at least 1 column.';
export const SELECT_COLUMN = 'Please select column.';
export const NO_SUBMISSIONS_FOUND = 'No submissions found.';
export const ENTER_VALID_TABLE_ENTRIES = 'Each entry in table is required to be filled.';
export const ADD_ATLEAST_ONE_TABLE_ROW = 'Please add at least 1 row in table with valid entries.';
export const NO_USERS_FOUND = 'No users found.';
export const KEWORD_AND_ATLEAST_ONE_COLUMN_REQUIRED = 'Enter search keyword and select at least 1 column.';
export const NO_COLLABORATIONS_FOUND = 'No collaborations found.';
export const ADD_ATLEAST_TWO_COLLABORATORS = 'Add at least 2 collaborators.';
export const ADD_ATLEAST_ONE_COLLABORATOR = 'Add at least 1 collaborator.';
export const ENTER_COLLABORATOR_LIST_NAME = 'Enter collaborator list name.';
export const COLLABORATOR_ALREADY_EXIST = 'Collaborator(s) already exist.';
export const NO_CHARACTERS_FOUND = 'No characters found for this style guide.';
export const ENTER_SKU_NUMBER = 'Enter SKU number';
export const MULTIPLE_SKU_NOT_ALLOWED = 'Multiple Sku not allowed.';
export const ENSURE_SUBMISSION_FORM_IS_VALID = 'Please ensure values entered in submission form are valid.';
export const SELECT_ONE_USER = 'Please select one user.';
export const CHECK_ACTIVE_DOWNLOADS = 'Download job will start momentarily. Please check the status under Active Downloads.';
export const USER_NOT_MASTER_COLLABORATOR = 'Only master collaborator can end/delete their own collaboration(s).';

// success alert messages
export const RENAME_SEARCH_SUCCESS = 'Search renamed successfully.';
export const SAVE_SEARCH_SUCCESS = 'Search saved successfully.';
export const DELETE_SEARCH_SUCCESS = 'Search deleted successfully.';
export const REFRESH_VIEWS_SUCCESS = 'Refreshed Views Successfully';
export const STATUS_SYNC_SUCCESS = 'Status Synced Successfully';
export const CONTRACT_SYNC_SUCCESS = 'Contract Synced Successfully.';
export const SKU_PUSH_SUCCESS = 'SKU Pushed Successfully.';
export const USER_DETAILS_UPDATE_SUCCESS = 'User details updated successfully.';
export const END_COLLABORATIONS_SUCCESS = 'Collaboration(s) eneded successfully.';
export const COLLABORATION_UPDATE_SUCCESS = 'Collaboration updated successfully.';
export const COLLABORATION_CREATE_SUCCESS = 'Collaboration created successfully.';
export const COLLABORATOR_TEMPLATE_DELETE_SUCCESS = 'Collaborator template deleted successfully.';
export const COLLABORATOR_TEMPLATE_CREATE_SUCCESS = 'Collaborator template created successfully.';
export const COLLABORATOR_TEMPLATE_UPDATE_SUCCESS = 'Collaborator template updated successfully.';
export const SUBMISSION_COPY_SUCCESS = 'Submission copied successfully.';
export const SUBMISSION_CREATE_SUCCESS = 'Submission created successfully.';
export const SUBMISSION_UPDATE_SUCCESS = 'Submission updated successfully.';
export const SUBMISSION_DISCARD_SUCCESS = 'Submission discarded sucessfully.';
export const SUBMISSION_SAVE_SUCCESS = 'Submission saved sucessfully.';
export const TASK_ACTION_SUCCESS = 'Task performed successfully.';
export const SUBMISSION_FORM_CLOSE_SUCCESS = 'Submission form successfully closed.';

// error alert messages
export const RENAME_SEARCH_FAILED = 'Failed to rename search.';
export const DELETE_SEARCH_FAILED = 'Failed to delete search.';
export const SAVE_SEARCH_FAILED = 'Failed to save search.';
export const REFRESH_VIEWS_FAILED = 'Error in Refresh View process.';
export const STATUS_SYNC_FAILED = 'Status Synced Failed.';
export const CONTRACT_SYNC_FAILED = 'Contract Sync Failed.';
export const FETCH_CONTRACT_DETAILS_FAILED = 'Failed to fetch contract details.';
export const SKU_PUSH_FAILED = 'SKU Push Failed.';
export const USER_DETAILS_UPDATE_FAILED = 'Failed to update user details.';
export const FETCH_USERS_FAILED = 'Failed to fetch users.';
export const COLLABORATION_UPDATE_FAILED = 'Failed to update collaboration.';
export const COLLABORATION_CREATE_FAILED = 'Failed to create collaboration.';
export const END_COLLABORATIONS_FAILED = 'Failed to end collaboration(s).';
export const COLLABORATOR_TEMPLATE_DELETE_FAILED = 'Failed to delete collaborator template.';
export const COLLABORATOR_TEMPLATE_CREATE_FAILED = 'Failed to create collaborator template.';
export const COLLABORATOR_TEMPLATE_UPDATE_FAILED = 'Failed to update collaborator template.';
export const FETCH_STYLE_GUIDES_FAILED = 'Failed to fetch style guides.';
export const FETCH_STYLE_GUIDES_CHARCTERS_FAILED = 'Failed to fetch style guide characters';
export const SKU_VALIDATION_FAILED = 'Failed to validate SKU';
export const SKU_ALREADY_EXISTS = 'SKU already exists, please provide a different SKU number.';
export const SUBMISSION_COPY_FAILED = 'Failed to copy submission.';
export const SUBMISSION_UPDATE_FAILED = 'Failed to update submission.';
export const SUBMISSION_CREATE_FAILED = 'Failed to create submission.';
export const SUBMISSION_SAVE_FAILED = 'Failed to save submission.';
export const TASK_ACTION_FAILED = 'Failed to perform task.';
export const AUTH_FAILED = 'Failed to authenticate, please try again.';
export const DOWNLOAD_FAILED = 'Failed to download file, please try again';

// uploads
export const ATTACHMENTS_UPLOADED_SUCCESSFULLY = 'Attachment uploaded successfully.';
export const ATTACHEMENT_DELETED_SUCCESSFULLY = 'Attachment has been deleted succesfully.';
export const ATTACHEMENT_DOWNLOADED_SUCCESSFULLY = 'Attachment downloaded sucessfully.';

export const ATTACHMENTS_UPLOADED_FAILED = 'Failed to upload attachments, please try again.';
export const ATTACHMENT_DELETION_FAILED = 'Failed to delete attachment, please try again.';
export const ATTACHEMENT_DOWNLOAD_FAILED = 'Failed to download attachments, please try again.';

export const ASSET_DOWNLOAD_FAILED = 'Failed to download the asset, please try again.';
export const ASSET_DOWNLOADED_SUCCESSFULLY = 'Asset downloaded sucessfully.';

export const ASSET_REMOVE_FROM_BASKET_FAILED = 'Failed to remove asset, please try again.';
export const ASSET_REMOVE_FROM_BASKET_SUCCESSFULLY = 'Asset has been removed succesfully.';

export const ASSET_ADDED_TO_BASKET_FAILED = 'Failed to add asset to basket, please try again.';
export const ASSET_ADDED_TO_BASKET_SUCCESSFULLY = 'Asset has been added to basket succesfully.';

// fact sheets
export const FACTSHEET_UPLOADED_SUCCESSFULLY = 'Fact Sheet uploaded sucessfully.';
export const FACTSHEET_UPLOADED_FAILED = 'Failed to upload fact sheet, please try again.';
export const FACTSHEET_FETCHING_FAILED = 'Failed to fetch fact sheet, please try again.';
