import { BASE_API_ENDPOINT, UPLOAD_ATTACHMENTS_SUBMISSIONS_ENDPOINT, DOWNLOAD_JOBS_ATTACHMENTS_ENDPOINT, BA_STYLE_GUIDES_ENDPOINT, DOWNLOAD_ATTACHMENTS_ENDPOINT } from '@/api/endpointConstants.js';
import { addTokenInRequestHeader } from '@/helpers/authCheck.js';
import axios from 'axios';
import { filterObject } from '@/helpers/util';

const uploadDownloadAxiosInstance = axios.create({
    baseURL: BASE_API_ENDPOINT + BA_STYLE_GUIDES_ENDPOINT
});

// request interceptor
uploadDownloadAxiosInstance.interceptors.request.use(request => {
    // add token in header
    request = addTokenInRequestHeader(request);
    return request;
});

export function uploadFilesService (data) {
    return uploadDownloadAxiosInstance({
        method: 'POST',
        url: UPLOAD_ATTACHMENTS_SUBMISSIONS_ENDPOINT,
        data
    });
}

export function finishMultiPartUploadService (data) {
    return uploadDownloadAxiosInstance({
        method: 'POST',
        url: `${UPLOAD_ATTACHMENTS_SUBMISSIONS_ENDPOINT}/complete`,
        data
    });
}

export function fetchUploadedFilesService (params) {
    return uploadDownloadAxiosInstance({
        method: 'GET',
        url: UPLOAD_ATTACHMENTS_SUBMISSIONS_ENDPOINT,
        params: filterObject(params)
    });
}

export function deleteUploadedFileService (params) {
    return uploadDownloadAxiosInstance({
        method: 'DELETE',
        url: `${UPLOAD_ATTACHMENTS_SUBMISSIONS_ENDPOINT}/${params.id}`
    });
}

export function uploadTenovasFilesService (data) {
    return '';
}

// download uploaded attachments
export function getDownloadAttachmentURLService (params) {
    return uploadDownloadAxiosInstance({
        method: 'GET',
        url: `${DOWNLOAD_ATTACHMENTS_ENDPOINT}/${params.id}`,
        params: filterObject({
          filename: params.filename,
          isSubmissionAttachment: params.isSubmissionAttachment,
          objectId: params.objectId || params.id,
          exportId: params.exportId,
        })
    });
}

export function fetchActiveDowloadListService (params) {
    return uploadDownloadAxiosInstance({
        method: 'GET',
        url: `${DOWNLOAD_ATTACHMENTS_ENDPOINT}${DOWNLOAD_JOBS_ATTACHMENTS_ENDPOINT}`
    });
}

export function fetchActiveDowloadJobByIdService (id) {
    return uploadDownloadAxiosInstance({
        method: 'GET',
        url: `${DOWNLOAD_ATTACHMENTS_ENDPOINT}${DOWNLOAD_JOBS_ATTACHMENTS_ENDPOINT}/${id}`
    });
}

export function createActiveDownloadJobsService (data) {
    return uploadDownloadAxiosInstance({
        method: 'POST',
        url: `${DOWNLOAD_ATTACHMENTS_ENDPOINT}`,
        data
    });
}

// --------- --------- --------- --------- ---------

const cleanAxiosInstance = axios.create({
    baseURL: ''
});

cleanAxiosInstance.interceptors.request.use(request => {
    // add token in header
    request = addTokenInRequestHeader(request);
    return request;
});

export function getDownloadAttachmentURLViaUrlService (params) {
    return cleanAxiosInstance({
        method: 'GET',
        url: params.url
    });
}
