import { uploadFilesService, finishMultiPartUploadService, fetchUploadedFilesService, uploadTenovasFilesService, deleteUploadedFileService, getDownloadAttachmentURLService, getDownloadAttachmentURLViaUrlService } from '@/api/requests/uploadRequests';

export default {
    namespaced: true,

    state () {
        return {
            uploadFileData: null,
            uploadTenevosFileData: null,
            uploadedFiles: null,
            downloadURLAttachment: null,
            downloadURLAttachmentViaUrl: null
        };
    },

    getters: {
        getUploadData (state) {
            return state.uploadFileData;
        },
        getUploadTenevosFileData (state) {
            return state.uploadTenevosFileData;
        },
        getDeletedFileData (state) {
            return state.deleteFileData;
        },
        getUploadedFiles (state) {
            return state.uploadedFiles;
        },
        getDownloadURL (state) {
            return state.downloadURLAttachment;
        },
        // getDownloadURLViaUrl (state) {
        //     return state.downloadURLAttachmentViaUrl;
        // }
    },

    mutations: {
        setUploadFile (state, uploadData) {
            state.uploadFileData = uploadData;
        },
        setUploadTenevosFile (state, uploadTenevosData) {
            state.uploadTenevosFileData = uploadTenevosData;
        },
        setDeleteUploadFile (state, deleteData) {
            state.deleteFileData = deleteData;
        },
        setFetchedUploadedFiles (state, uploadedFiles) {
            state.uploadedFiles = uploadedFiles;
        },
        setDownloadAttachmentURL (state, downloadURLAttachment) {
            state.downloadURLAttachment = downloadURLAttachment;
        },
        // setDownloadAttachmentURLViaUrl (state, downloadURLAttachmentViaUrl) {
        //     state.downloadURLAttachmentViaUrl = downloadURLAttachmentViaUrl;
        // }
    },

    actions: {
        async fetchUploadedFiles ({ commit }, { params }) {
            const { data: { data } } = await fetchUploadedFilesService(params);
            commit('setFetchedUploadedFiles', data);
        },
        async uploadingFileTenevos ({ commit }, passData) {
            const { data: { data } } = await uploadTenovasFilesService(passData);
            commit('setUploadTenevosFile', data);
        },
        async uploadFiles ({ commit }, { payload }) {
            const { data: { data } } = await uploadFilesService(payload);
            commit('setUploadFile', data);
        },
        async finishMultiPart ({ commit }, { payload }) {
            return finishMultiPartUploadService(payload);
        },
        async deleteUploadedFile ({ commit }, { params }) {
            const { data: { data } } = await deleteUploadedFileService(params);
            commit('setDeleteUploadFile', data);
        },
        async getDownloadAttachmentURL ({ commit }, { params }) {
            const { data: { data } } = await getDownloadAttachmentURLService(params);
            // commit('setDownloadAttachmentURL', data);
            return data
        },
        async getDownloadAttachmentURLViaUrl ({ commit }, { params }) {
            const { data: { data } } = await getDownloadAttachmentURLViaUrlService(params);
            return data
        },
        resetAll ({ commit }) {
            commit('setUploadFile', null);
        }
    }
};
