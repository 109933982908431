// Style Guide Groups
export const SG_GROUP_ORDER = {
  'Corporate': 1,
  'Style Guide-Event Film Priorities': 2,
  "Style Guide-Marvel Studios' Disney Plus TV Shows": 3,
  'Style Guide-Evergreen Priorities': 4,
  'Style Guide-Opportunity': 5,
  'Style Guide-Case by Case': 6,
  'Style Guide-Out of Circulation': 7,
  'Promotional Theatrical Art Packs': 8,
  'Promotional Theatrical Art Pack-Out of Circulation': 9,
  'Style Guide-Completely Hidden Guides': 10,
  'Corporate Tool Kits': 11,
  'Evergreen Priorities Tool Kits': 12,
  'Event Film Priorities Tool Kits': 13,
  'Opportunity Tool Kits': 14
}
