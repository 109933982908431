import { fetchStyleGuideDirectoryService, fetchStyleGuideBannerService } from '@/api/requests/styleGuidesRequests.js';

import { SG_GROUP_ORDER } from '@/constants/styleGuideGroups.js';

const sortOnDisplayOrderOrByGivenKey = (objArray, key) => {
  if (!objArray || !key) return

  objArray.sort((a, b) =>
    (parseInt(a.displayOrder) || 100000) < (parseInt(b.displayOrder) || 100000)
      ? -1
      : (parseInt(a.displayOrder) && parseInt(b.displayOrder))
        ? 1
        : a[key] < b[key]
          ? -1
          : 1
  )

  // Nothing to return .. sort happens in place
}

export default {
    namespaced: true,

    state () {
        return {
            styleGuideDirectory: null,
            childNodes: null,
            selectedNodeStack: null,
            bannerList: null
        };
    },

    getters: {
        getStyleGuideDirectory (state) {
            return state.styleGuideDirectory;
        },
        getStyleGuideGroups (state) {
            let retValue = {}
            state.styleGuideDirectory.forEach(g => {
              g.styleGuides.forEach(sg => retValue[sg.styleGuideName] = g.title )
            })
            return retValue;
        },
        getStyleGuideBanner (state) {
            return state.bannerList;
        },
        getChildNodes (state) {
            return state.childNodes;
        },
        getSelectedNode ({ selectedNodeStack }) {
            return selectedNodeStack && selectedNodeStack.length ? selectedNodeStack[selectedNodeStack.length - 1] : null;
        },
        getSelectedStyleGuide ({ selectedNodeStack }) {
            return selectedNodeStack && selectedNodeStack.length ? selectedNodeStack[0] : null;
        },
        getSelectedNodeStack (state) {
            return state.selectedNodeStack;
        }
    },

    mutations: {
        setStyleGuideBanner (state, data) {
            state.bannerList = data;
        },
        setStyleGuideDirectory (state, directoryData) {
            // add folder icon
            if (directoryData?.length > 0) {
                // Sort the SG Groups
                directoryData.sort((a, b) => (SG_GROUP_ORDER[a.title] || 10000) > (SG_GROUP_ORDER[b.title] || 10000) ? 1 : -1)

                // Process each SG
                directoryData.forEach((node) => {

                    node.children = []
                    const alreadyAdded = []
                    if (node.styleGuides.length) node.styleGuides.map(sg => {
                      if (alreadyAdded.includes(sg.styleGuideName)) return
                      alreadyAdded.push(sg.styleGuideName)
                      node.children.push(sg)
                    });

                    if (node?.children?.length) {
                        node.children = node?.children.map(childNode => {
                            const uniqueId = childNode.styleGuideName || Math.floor(Math.random() * 10000000).toString();
                            childNode.buttons = childNode?.buttons?.filter(btn => btn.renditions)
                            if (childNode.buttons && childNode.buttons.length && childNode.buttons[0].metadataTemplateName == 'Artwork'){
                              childNode.isPublicSg = true
                              childNode.buttons = []
                              childNode.mrlc_attr_tier_1 = childNode.styleGuideName
                            }

                            sortOnDisplayOrderOrByGivenKey(childNode?.buttons, 'buttonName')
                            childNode.buttons = childNode?.buttons?.map(btn => {
                                return {
                                  ...btn,
                                  id: Math.floor(Math.random() * 10000000).toString(),
                                  path: uniqueId,
                                  toggle: false,
                                  directoryIcon: require('@/assets/images/icons/folder.svg'),
                                  mrlc_attr_tier_2: btn.buttonName,
                                  children: []
                                }
                            });

                            return {
                                ...childNode,
                                id: uniqueId,
                                path: '',
                                children: childNode.buttons,
                                toggle: false,
                                directoryIcon: require('@/assets/images/icons/folder.svg')
                            };
                        });

                        sortOnDisplayOrderOrByGivenKey(node?.children, 'styleGuideName')
                    }
                });
            }
            state.styleGuideDirectory = directoryData;
        },
        setChildNodes (state, { parentNode, childNodes }) {
            const uniqueId2 = `${parentNode.path}/${parentNode.id}`;

            childNodes = childNodes?.filter(node => node.renditions)
            sortOnDisplayOrderOrByGivenKey(childNodes, 'buttonName')
            childNodes = childNodes?.map(node =>
                ({
                    ...node,
                    toggle: false,
                    id: Math.floor(Math.random() * 10000000).toString(),
                    path: uniqueId2,
                    directoryIcon: require('@/assets/images/icons/folder.svg'),
                    children: [],
                    [`mrlc_attr_tier_${Object.keys(node).filter(key => key.includes('mrlc_attr_tier_')).length + 1}`]: node.buttonName
                })
            );

            state.childNodes = childNodes;
            // add folder icon

            // link child nodes to parent node
            parentNode.children = childNodes;
        },
        updateSelectedNodeStack (state, selectedNode) {
            // intialize stack if it's null
            if (!state.selectedNodeStack) {
                state.selectedNodeStack = [];
            }
            if (state.selectedNodeStack.length) {
                const matchedNodeIndex = state.selectedNodeStack.findIndex(node => node.path === selectedNode.path);
                if (matchedNodeIndex !== -1) {
                    // if selected node's path matched any node in stack(i.e both nodes are siblings) then perform following operations

                    // collapse all nodes down the stack till the sibling
                    state.selectedNodeStack.reverse().some(node => {
                        node.toggle = false;
                        return node.path === selectedNode.path;
                    });
                    // reverse back
                    state.selectedNodeStack.reverse();
                    // remove all collapsed nodes from stack
                    state.selectedNodeStack = state.selectedNodeStack.slice(0, matchedNodeIndex);
                } else if (!selectedNode.path.includes(state.selectedNodeStack[state.selectedNodeStack.length - 1].id)) {
                    // if selected node is not related to top node (neither descendent nor sibling) collapse every node in stack
                    state.selectedNodeStack.forEach(node => {
                        node.toggle = false;
                    });
                    // flush stack
                    state.selectedNodeStack = [];
                }
            }
            // add selected node to stack
            state.selectedNodeStack.push(selectedNode);
            // expand selected node
            selectedNode.toggle = true;
        },
        clearSelectedNodeStack (state) {
            state.selectedNodeStack.forEach(node => {
                node.toggle = false;
            });
            state.selectedNodeStack = [];
        },
        updateSelectedNodesToggle (state, toggleValue) {
            state.selectedNodeStack.forEach(node => {
                node.toggle = toggleValue;
            });
        }
    },

    actions: {
        async fetchStyleGuideBanner ({ commit }, { params }) {
            const { data: { data } } = await fetchStyleGuideBannerService(params);
            commit('setStyleGuideBanner', data);
        },
        async fetchStyleGuideDirectory ({ commit, rootGetters }, { params }) {
            const selectedLicensee = rootGetters['auth/getSelectedLicensee'];
            if (selectedLicensee) params.selectedLicenseeName = selectedLicensee.licenseeName

            const { data: { data } } = await fetchStyleGuideDirectoryService(params);
            commit('setStyleGuideDirectory', data);
        },
        async fetchChildNodes ({ commit, dispatch, rootGetters }, { params, parentNode }) {
            const selectedLicensee = rootGetters['auth/getSelectedLicensee'];
            if (selectedLicensee) params.selectedLicenseeName = selectedLicensee.licenseeName

            const { data: { data } } = await fetchStyleGuideDirectoryService(params);
            if (data?.facets) {
                const tmpParams = { ...params }
                delete tmpParams.metadataTemplateName
                Object.keys(tmpParams).forEach(key => tmpParams[key] === undefined && delete tmpParams[key])
                commit('assetsSearch/setSearchParams', tmpParams, { root: true });

                // Sort the asset based on displayOrder
                data.data.sort((a, b) => (parseInt(a.displayOrder) || 100000) > (parseInt(b.displayOrder) || 100000) ? 1 : -1)

                commit('assets/setAssetsList', data, { root: true });
            } else if (data?.length) {
                commit('setChildNodes', { parentNode, childNodes: data });
            }
        },
        async loadNodes ({ commit, dispatch }, { nodeData, page, limit }) {
            // clear assets list
            commit('assets/setAssetsList', null, { root: true });
            // perform operations only if selected node is folder

            if ('children' in nodeData || nodeData.isPublicSg) {
                commit('updateSelectedNodeStack', nodeData);
                if (nodeData.children && !nodeData.children.length) {
                    await dispatch('fetchChildNodes', {
                        params: {
                            metadataTemplateName: 'Button',
                            mrlc_attr_tier_1: nodeData.mrlc_attr_tier_1,
                            mrlc_attr_tier_2: nodeData.mrlc_attr_tier_2,
                            mrlc_attr_tier_3: nodeData.mrlc_attr_tier_3,
                            mrlc_attr_tier_4: nodeData.mrlc_attr_tier_4,
                            mrlc_attr_tier_5: nodeData.mrlc_attr_tier_5,
                            page,
                            limit
                        },
                        parentNode: nodeData
                    });
                }
                await dispatch('fetchStyleGuideBanner', {
                    params: {
                        metadataTemplateName: 'Banner',
                        styleGuideName: nodeData.styleGuideName,
                        mrlc_attr_tier_1: nodeData.mrlc_attr_tier_1,
                        mrlc_attr_tier_2: nodeData.mrlc_attr_tier_2,
                        mrlc_attr_tier_3: nodeData.mrlc_attr_tier_3,
                        mrlc_attr_tier_4: nodeData.mrlc_attr_tier_4,
                        mrlc_attr_tier_5: nodeData.mrlc_attr_tier_5
                    }
                })
            }
        },
        collapseSelectedNodes ({ commit, state }) {
            if (state.selectedNodeStack && state.selectedNodeStack.length) {
                commit('updateSelectedNodesToggle', false);
            }
        },
        resetSelectedNodeStack ({ commit, state }) {
            if (state.selectedNodeStack && state.selectedNodeStack.length) {
                commit('clearSelectedNodeStack');
            }
        },
        resetStyleGuideTree ({ commit }) {
            commit('setStyleGuideDirectory', null);
        }
    }
};
