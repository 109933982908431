import {
    BASE_MOCK_API_ENDPOINT, MASTER_DATA_GAME_CODE_ENDPOINT, CUSTOM_LINES_ENDPOINT,
    BRAND_ASSURANCE_ENDPOINT, VALIDATE_MULTIPLE_SKU_ENDPOINT, CLAIM_TASKS_ENDPOINT,
    MOCK_SUBMISSION_ENDPOINT, UNCLAIM_TASKS_ENDPOINT, ASSIGN_TASKS_ENDPOINT,
    REASSIGN_TASKS_ENDPOINT, SUBMISSION_CATEGORIES_ENDPOINT, SUBMISSION_LICENSEE_CONTACT_ENDPOINT,
    SUBMISSION_CONTRACTS_ENDPOINT, SUBMITTION_COPY_ENDPOINT, CANCEL_SUBMISSION_ENDPOINT, SUBMISSION_DETAILS_ENDPOINT,
    BASE_API_ENDPOINT, SUBMISSION_ENDPOINT, GENERATE_SUBMISSION_ID_ENDPOINT, VALIDATE_SKU_ENDPOINT,
    SUBMISSION_WORKITEMS_ENDPOINT, SUBMISSION_TASKS_ENDPOINT, SUBMISSION_COMMENT_ENDPOINT, SUBMISSION_SEARCH_ENDPOINT,
    SUBMISSION_ADVANCE_SEARCH_ENDPOINT, SUBMISSION_SAVED_SEARCH_ENDPOINT, RECEIPT_ENDPOINT, ANNUAL_SAMPLES_ENDPOINT,
    SUBMISSION_PKGING_ENDPOINT, MANUAL_SKU_PUSH_ENDPOINT, EMAIL_ENDPOINT
} from '@/api/endpointConstants.js';
import { addTokenInRequestHeader } from '@/helpers/authCheck.js';
import { filterObject } from '@/helpers/util';
import axios from 'axios';

// @TO-DO's: remove mock api instance once mock services are not required for submissions

// intialize local instance
const baMockSubmissionAxiosInstance = axios.create({
    baseURL: BASE_MOCK_API_ENDPOINT + BRAND_ASSURANCE_ENDPOINT + MOCK_SUBMISSION_ENDPOINT
});

// request interceptor
baMockSubmissionAxiosInstance.interceptors.request.use(request => {
    // add token in header
    request = addTokenInRequestHeader(request);
    return request;
});

export function fetchSubmissionsService (params) {
    return baMockSubmissionAxiosInstance({
        method: 'GET',
        params: filterObject(params)
    });
}

export function cancelSubmissionService (submissionId) {
    return baMockSubmissionAxiosInstance({
        method: 'PUT',
        url: `/${submissionId}${CANCEL_SUBMISSION_ENDPOINT}`
    });
}

export function claimTaskService (data) {
    return baMockSubmissionAxiosInstance({
        method: 'PUT',
        url: CLAIM_TASKS_ENDPOINT,
        data
    });
}

export function unclaimTaskService (data) {
    return baMockSubmissionAxiosInstance({
        method: 'PUT',
        url: UNCLAIM_TASKS_ENDPOINT,
        data
    });
}

export function assignTasksService (data) {
    return baMockSubmissionAxiosInstance({
        method: 'PUT',
        url: ASSIGN_TASKS_ENDPOINT,
        data
    });
}

export function reAssignTasksService (data) {
    return baMockSubmissionAxiosInstance({
        method: 'PUT',
        url: REASSIGN_TASKS_ENDPOINT,
        data
    });
}

export function fetchSubmissionCategoriesService () {
    return baMockSubmissionAxiosInstance({
        method: 'GET',
        url: SUBMISSION_CATEGORIES_ENDPOINT
    });
}

export function updateLicenseeContactService (submissionId, data) {
    return baMockSubmissionAxiosInstance({
        method: 'PUT',
        url: `${submissionId}${SUBMISSION_LICENSEE_CONTACT_ENDPOINT}`,
        data
    });
}

export function fetchSubmissionsContractsService (submissionId, params) {
    return baMockSubmissionAxiosInstance({
        method: 'GET',
        url: `${submissionId}${SUBMISSION_CONTRACTS_ENDPOINT}`,
        params: filterObject(params)
    });
}

export function updateSubmissionContractService (submissionId, data) {
    return baMockSubmissionAxiosInstance({
        method: 'PUT',
        url: `${submissionId}${SUBMISSION_CONTRACTS_ENDPOINT}`,
        data
    });
}

// *******************

const baSubmissionAxiosInstance = axios.create({
    baseURL: BASE_API_ENDPOINT + SUBMISSION_ENDPOINT
});

// request interceptor
baSubmissionAxiosInstance.interceptors.request.use(
    request => {
        // add token in header
        request = addTokenInRequestHeader(request);
        return request;
    }
);

export function generateIdentifierService (params) {
    return baSubmissionAxiosInstance({
        method: 'GET',
        url: GENERATE_SUBMISSION_ID_ENDPOINT,
        params: filterObject(params)
    });
}

export function validateSKUService (data) {
    return baSubmissionAxiosInstance({
        method: 'POST',
        url: VALIDATE_SKU_ENDPOINT,
        data
    });
}

export function validateMultipleSKUService (data) {
    return baSubmissionAxiosInstance({
        method: 'POST',
        url: VALIDATE_MULTIPLE_SKU_ENDPOINT,
        data
    });
}

export function searchSubmissionsService (data) {
    return baSubmissionAxiosInstance({
        method: 'POST',
        url: `${SUBMISSION_WORKITEMS_ENDPOINT}${SUBMISSION_SEARCH_ENDPOINT}`,
        data: filterObject(data)
    });
}

export function searchExportExcelSubmissionsService (data) {
    return baSubmissionAxiosInstance({
        method: 'POST',
        url: `${SUBMISSION_WORKITEMS_ENDPOINT}${SUBMISSION_SEARCH_ENDPOINT}`,
        data: filterObject(data)
    });
}

export function fetchSubmissionComment (params) {
    return baSubmissionAxiosInstance({
        method: 'GET',
        url: `${SUBMISSION_COMMENT_ENDPOINT}`,
        params: filterObject(params)
    });
}

export function deleteSubmissionComment (params) {
    return baSubmissionAxiosInstance({
        method: 'DELETE',
        url: `${SUBMISSION_COMMENT_ENDPOINT}`,
        params: filterObject(params)
    });
}

export function fetchSubmissionDetailsService (submissionId, params) {
    return baSubmissionAxiosInstance({
        method: 'GET',
        url: `${submissionId}${SUBMISSION_DETAILS_ENDPOINT}`,
        params: filterObject(params)
    });
}

export function submissionTasksService (data) {
    return baSubmissionAxiosInstance({
        method: 'POST',
        url: `${SUBMISSION_TASKS_ENDPOINT}`,
        data
    });
}

export function advanceSearchSubmissionsService (data) {
    return baSubmissionAxiosInstance({
        method: 'POST',
        url: `${SUBMISSION_ADVANCE_SEARCH_ENDPOINT}`,
        data: filterObject(data)
    });
}

export function fetchSubmissionSavedSearchService (params) {
    return baSubmissionAxiosInstance({
        method: 'GET',
        url: `${SUBMISSION_ADVANCE_SEARCH_ENDPOINT}${SUBMISSION_SAVED_SEARCH_ENDPOINT}`,
        params: filterObject(params)
    });
}

export function createSubmissionSavedSearchService (data) {
    return baSubmissionAxiosInstance({
        method: 'POST',
        url: `${SUBMISSION_ADVANCE_SEARCH_ENDPOINT}${SUBMISSION_SAVED_SEARCH_ENDPOINT}`,
        data
    });
}

export function updateSubmissionSavedSearchService (data, searchKey) {
    return baSubmissionAxiosInstance({
        method: 'PUT',
        url: `${SUBMISSION_ADVANCE_SEARCH_ENDPOINT}${SUBMISSION_SAVED_SEARCH_ENDPOINT}/${searchKey}`,
        data
    });
}

export function deleteSubmissionSavedSearchService (searchKey) {
    return baSubmissionAxiosInstance({
        method: 'DELETE',
        url: `${SUBMISSION_ADVANCE_SEARCH_ENDPOINT}${SUBMISSION_SAVED_SEARCH_ENDPOINT}/${searchKey}`
    });
}

export function masterDataSubmissionGameCodesService (params) {
    return baSubmissionAxiosInstance({
        method: 'GET',
        url: `${MASTER_DATA_GAME_CODE_ENDPOINT}`,
        params: filterObject(params)
    });
}

export function fetchCustomLinesSubmissionService () {
    return baSubmissionAxiosInstance({
        method: 'GET',
        url: `${SUBMISSION_COMMENT_ENDPOINT}${CUSTOM_LINES_ENDPOINT}`
    });
}

export function fetchPackagingSubmissionNumber (data) {
    return baSubmissionAxiosInstance({
        method: 'POST',
        url: `${SUBMISSION_PKGING_ENDPOINT}`,
        data
    });
}

export function createCopyOfSubmissionService (data) {
    return baSubmissionAxiosInstance({
        method: 'POST',
        url: `${SUBMITTION_COPY_ENDPOINT}`,
        data
    });
}

export function deleteSubmissionService (payload) {
    const { submissionId, submissionSkuKeys } = payload;
    return baSubmissionAxiosInstance({
        method: 'DELETE',
        url: `/${submissionId}`,
        params: { submissionSkuKeys }
    });
}

export function downloadSubmissionReceiptService (submissionId) {
    return baSubmissionAxiosInstance({
        method: 'GET',
        url: `/${submissionId}${RECEIPT_ENDPOINT}`
    });
}

export function initAnnualSamplesService (data) {
    return baSubmissionAxiosInstance({
        method: 'POST',
        url: `${ANNUAL_SAMPLES_ENDPOINT}`,
        data
    });
}

export function manualSKUPushService (data) {
    return baSubmissionAxiosInstance({
        method: 'POST',
        url: `${MANUAL_SKU_PUSH_ENDPOINT}`,
        data
    });
}


// *******************

const baseAxiosInstance = axios.create({
    baseURL: BASE_API_ENDPOINT
});

// request interceptor
baseAxiosInstance.interceptors.request.use(
    request => {
        // add token in header
        request = addTokenInRequestHeader(request);
        return request;
    }
);

export function resendNotificationService (data) {
    return baseAxiosInstance({
        method: 'GET',
        url: `${EMAIL_ENDPOINT}`,
        params: data
    });
}
